/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
// @import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
// @import '@ionic/angular/css/normalize.css';
// @import '@ionic/angular/css/structure.css';
// @import '@ionic/angular/css/typography.css';
// @import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
// @import '~@ionic/angular/css/padding.css';
// @import '~@ionic/angular/css/float-elements.css';
// @import '~@ionic/angular/css/text-alignment.css';
// @import '~@ionic/angular/css/text-transformation.css';
// @import '~@ionic/angular/css/flex-utils.css';

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;800&display=swap');
// @import 'swiper/scss';
// @import 'swiper/scss/navigation';
// @import 'swiper/scss/pagination';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// @import 'swiper/css/bundle';

:root {
  --ion-font-family: 'Poppins', sans-serif;
}

ion-modal{
    --width: 100%;
    --height: 100%;
}
ion-toolbar{
  --background: transparent;
}
ion-header{
  --background: transparent;
}

// .StayCard:hover{
    // .swiper-button-prev {
    //     background-color: var(--ion-color-light);
    //     border-radius: 50%;
    //     background-image: url(assets/icons/arrow-back.svg);
    //     background-repeat: no-repeat;
    //     background-size: 50% auto;
    //     background-position: center;
    //     width: 2rem;
    //     height: 2rem;

    // }

    // .swiper-button-next {
    //     background-color: var(--ion-color-light);
    //     border-radius: 50%;
    //     background-image: url(assets/icons/arrow-forward.svg);
    //     background-repeat: no-repeat;
    //     background-size: 50% auto;
    //     background-position: center;
    //     width: 2rem;
    //     height: 2rem;
    //   }

    //   .nc-NcImage{
    //     transition: transform .4s ease-out;
    //     transform: scale(1.1);
    //   }



// }

// .StayCard{

// }



// .swiper-button-prev::after {
//     display: none;
// }



//   .swiper-button-next::after {
//     display: none;
//   }
